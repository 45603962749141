export const parti1 =
  "In Multi-Agent Systems (MAS), effective coordination and cooperation among the robots are pivotal for mission success. This collaboration aids in tasks such as team motion planning, navigation enhancement, and target state estimation. The MAXFISH project is dedicated to advancing both the methodological and implementation frameworks in this direction.";

export const part2 =
  "To implement coordination, MAXFISH will define agents using existing robots, knowledge, and equipment to establish basic sensors and actuators. Two reference robots, Low-Cost Fish and High-Performance Fish, will guide agent composition. We'll study modeling and control solutions for an efficient Navigation, Guidance and Control (NGC) structure, enhancing robotic shoal interventions in intrusion detection and patrolling.";

export const part3 =
  "For implementing any kind of coordination, an exchange of information is needed among the agents composing the team in order to take decisions about the actions to perform. Two possible architectures can be envisioned: centralized and distributed. In a centralized architecture one of the agents acts as the leader of the team gathering information by all the agents and it is the decision center of the team; on the other hand, in a distributed architecture all the agents take decisions on their own, gathering information by their neighbors and locally processing them. Distributed architectures are preferred in MAS due to fault tolerance, reduced communication needs, cost-efficiency, and scalability.";

export const part4 =
  "In MAS, area coverage is a common task that leverages the collective capabilities of multiple agents to efficiently cover a designated area. This task involves repeated traversing of an area to reduce uncertainty in detecting unexpected events. Cooperative navigation and positioning in MAS involve data fusion and state estimation. Autonomous Underwater Vehicles (AUVs) communicate, exchange data, and process state information based on sensor measurements. They generate state estimations by combining local measurement data with received information. MAS can effectively estimate the state of underwater noise sources using sensors like hydrophones, coupled with beamforming algorithms to determine the source's direction.";

export const part5 =
  "One aspect of the MAXFISH project focuses on establishing a methodological framework to model and control a group of fish-like robots with varying sensor equipment and mobility capabilities. Due to the diversity of sensors and mobility features among the fish robots, different robots may need to survey specific points of interest. Additionally, factors like the complexity of points of interest and varying travel times make the patrolling task intricate. The Max-Plus algebra formalism is chosen to describe the actors, scenario, and tasks involved in this context.  ";

export const part6 =
  "The modeling process serves as the initial step, followed by the formulation of a control problem that ensures the fish robots adhere to a predefined behavior model. Finally, the developed tools and control methods will be validated within a local simulation environment.  ";

export const part7 =
  "Throughout the project's development, we remain dedicated to pushing the boundaries of underwater robotics and advancing the field of Multi-Agent Systems. Our ultimate aim is to contribute valuable insights and practical solutions that will have a lasting impact on the world of autonomous underwater exploration and intervention. ";

export const part8 =
  "Join us on this exciting journey as we explore the depths of innovation, cooperation, and discovery in the MAXFISH project.";

import { SelectedPage } from "../../shared/types";
import AnimatedOverline from "../AnimatedOverline";
import { Link, NavLink } from "react-router-dom";

type Props = {
  page: string;
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
};

const LinkItem = ({ page, selectedPage, setSelectedPage }: Props) => {

  const lowerCasePage = page
    .toLocaleLowerCase()
    .replace(/ /g, "") as SelectedPage;

  return (
    <NavLink
      to={`/${lowerCasePage}`}
      className={`
      decoration-white decoration-4 text-white
        `}
      onClick={() => {
        setSelectedPage(lowerCasePage);
      }}
    >
      <AnimatedOverline clicked={selectedPage === lowerCasePage}>
        {page}
      </AnimatedOverline>
    </NavLink>
  );
};

export default LinkItem;

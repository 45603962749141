import { Screens, SelectedPage } from "../../shared/types";
import AnimatedOverline from "../AnimatedOverline";
import AnimatedWordBar from "../AnimatedWordBar";

import { NavLink } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
type Props = {
  page: string;
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  setIsMenuToggled: (value: boolean) => void;
};

const Link = ({
  page,
  selectedPage,
  setSelectedPage,
  setIsMenuToggled,
}: Props) => {
  const lowerCasePage = page
    .toLocaleLowerCase()
    .replace(/ /g, "") as SelectedPage;

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-big";
    } else if (isAboveLargeScreen) {
      return "text-big";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else {
      return "text-4xl";
    }
  }

  return (
    <NavLink
      className={`
      ${getTitleDimens()} font-extrabold text-white 
        `}
      to={`/${lowerCasePage}`}
      onClick={() => {
        setSelectedPage(lowerCasePage);
        setIsMenuToggled(false);
      }}
    >
      <AnimatedWordBar clicked={selectedPage === lowerCasePage}>
        {page}
      </AnimatedWordBar>
    </NavLink>
  );
};

export default Link;

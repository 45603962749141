import { useRef } from "react";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import ScrollToTopButton from "../../Components/ScrollToTopButton";
import BannerImage from "../../assets/backgroundPrivate.png";
import IT from "../../assets/flags/it.png";
import Footer from "../../Components/Footer";
import MurLogo from "../../assets/mur.png";
import FooterMain from "../../Components/FooterMain";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;

  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;

  previousDiv: String;
  setPreviousDiv: (value: String) => void;

  setSelectedPage(value: SelectedPage): void;
};

function PublicationsPage({
  buttonHover,
  setButtonHover,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
  previousDiv,
  setPreviousDiv,
  setSelectedPage,
}: Props) {
  const aboutRef = useRef<HTMLDivElement>(null);
  const isAboveSmallcreens = useMediaQuery(Screens.Small);

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen) {
      return "text-3xl";
    } else if (isAboveSmallScreen) {
      return "text-2xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg text-center";
    }
  }
  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }
  return (
    <main
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className={`relative  w-full  bg-cover bg-center ${
        isAboveSmallScreen ? `${getPageDimens()}` : "h-screen bg-cover "
      }`}
    >
      <div
        className="w-full h-full"
        id="publications"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("publications");
        }}
        ref={ref}
        style={{
          backgroundImage: `url(${BannerImage})`,
        }}
      >
        <div
          className={`relative  w-full h-full bg-cover bg-center flex flex-col ${
            isAboveSmallScreen ? "items-start" : "items-center"
          }  justify-start pt-[calc(20px)]   gap-10`}
        >
          <div className={`text-white mt-2 ${getTitleDimens()} ${isAboveSmallScreen ? "ml-20" : ""} `}>
            List of Publications
          </div>
          <div
            className={`absolute ${
              isAboveSmallScreen ? "bottom-10 right-20 w-40 h-40" : "bottom-40 w-40"
            }   flex flex-col justify-center items-center gap-4`}
          >
            <a href="https://www.mur.gov.it/it" target="_new">
              <div
                className="w-12 h-12 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${MurLogo})`,
                }}
              />
            </a>
            <div className={`text-xs text-white`}>20225RYMJE</div>
            <div
              className={`flex w-full  h-[calc(20px)] justify-center items-center gap-5`}
            >
              <img className="w-1/5" src={IT} />

              <div className="text-white w-4/5">Progetto PRIN</div>
            </div>
          </div>{" "}
        </div>
      </div>
    </main>
  );
}

export default PublicationsPage;

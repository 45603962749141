import { useContext, useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, Models, Documents, Minutes } from "../../../shared/types";
import MURLogo from "../../../assets/mur.png";
import BannerImage from "../../../assets/backgroundPrivate.png";
import { auth } from "../../../firebaseSetup";
import { AuthContext } from "../../../AuthContext";
import Login from "./login";
import TextField from "../../../Components/TextField";
import Template from "../../../assets/documents/template.pptx";
import ack from "../../../assets/documents/acknowledgement.txt";
import cup from "../../../assets/documents/cup.txt";
import logo from "../../../assets/documents/logo.svg";
import logoPng from "../../../assets/logo.png";
import IT from "../../../assets/flags/it.png";
import annex2 from "../../../assets/documents/DD_n_104_Allegato_2_Criteri_per_la_determinazione_dei_costi.pdf";
import proposal from "../../../assets/documents/proposal.pdf";
import letterDoc from "../../../assets/documents/letter.docx";
import letterZip from "../../../assets/documents/Letter.zip";
import roles from "../../../assets/documents/roles.xlsx";
import poster from "../../../assets/documents/poster.pdf";
import kickoffCassino from "../../../assets/documents/kickoff_Cassino.pdf";
import kickoffBologna from "../../../assets/documents/kickoff_Bologna.pdf";
import kickoffCassinoWp3Introduction from "../../../assets/documents/kickoff_cassino_wp3_introduction.pdf";

import PNRRPoster from "../../../assets/documents/PNRR_MUR_Poster_A3.pptx";
import PNRRMURPresentazione from "../../../assets/documents/PNRR_MUR_Presentazione power point.pptx";
import PNRR_MUR_RELAZIONE from "../../../assets/documents/PNRR_MUR_Relazione.docx";
import PNRComunicati from "../../../assets/documents/PNRR_MUR_Comunicato generale_BLU_loghi header.docx";
import MUR_PNRR_PPT_FIRMA from "../../../assets/documents/MUR_PNRR_PPT_Loghi Firma.pptx";

import NotaChiarimenti from "../../../assets/documents/NOTA CHIARIMENTI COMUNICAZIONE.0026278.28-12-2023.pdf";
import LoghiItalia from "../../../assets/documents/Loghi Italiadomani.zip";
import LoghiMur from "../../../assets/documents/Loghi MUR.zip";
import LoghiNextGen from "../../../assets/documents/Loghi NextGenEU.zip";

import PresentazioneMaxfish220424DiLillo from "../../../assets/documents/Presentazione_Maxfish_220424_Di_Lillo.pptx";

import Introduzione_Maxfish_220424_Antonelli from "../../../assets/documents/Introduzione_Maxfish_220424_Antonelli.pdf";

import Presentazione_Maxfish_220424_UNIBO from "../../../assets/documents/220424_Presentazione_Maxfish_UNIBO.pptx";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;
  selectedDiv: String;
  setSelectedDiv: (value: string) => void;
};

const Private = ({
  buttonHover,
  setButtonHover,
  selectedDiv,
  setSelectedDiv,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  const nextMeeting = "-";
  const googleLink = "-";

  const repository = "-";
  const presentation = "";

  const [itemToDownload, setItemToDownload] = useState<string>("");

  const modelItems: string[] = [
    Models.PowerPoint,
    Models.Ack,
    Models.Cup,
    Models.LogoSVG,
    Models.LogoPNG,
    Models.Letter,
    Models.LetterZip,
    Models.PNRRPoster,
    Models.PNRRMURPresentazione,
    Models.PNRR_MUR_RELAZIONE,
    Models.PNRComunicati,
  ];

  const documentsItems: string[] = [
    Documents.Proposal,
    Documents.Annex2,
    Documents.Roles,
    Documents.Poster,
    Documents.NotaChiarimenti,
    Documents.LoghiItalia,
    Documents.LoghiMur,
    Documents.LoghiNextGen,
  ];

  const minuteItems: string[] = [
    Minutes.KickoffCassino,
    Minutes.KickoffBologna,
    Minutes.KickoffCassinoWp3Introduction,
    Minutes.Presentazione_Maxfish_220424_Di_Lillo,
    Minutes.Introduzione_Maxfish_220424_Antonelli,
    Minutes.Presentazione_Maxfish_220424_UNIBO,
  ];

  const [modelToDownload, setModelToDownload] = useState<string>("");
  const [modelToDownloadPath, setModelToDownloadPath] = useState<string>("");
  const [idModelSelected, setIdModelSelected] = useState<number>(-1);

  const [documentsToDownload, setDocumentsToDownload] = useState<string>("");
  const [documentsToDownloadPath, setDocumentsToDownloadPath] =
    useState<string>("");
  const [idDocumentsSelected, setDocumentsSelected] = useState<number>(-1);

  const [idSelected, setIdSelected] = useState<number>(-1);

  const [minuteToDownload, setMinuteToDownload] = useState<string>("");
  const [minuteToDownloadPath, setMinuteToDownloadPath] = useState<string>("");

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getTitle2Dimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen) {
      return "text-4xl";
    } else if (isAboveSmallScreen) {
      return "text-3l";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  const user = useContext(AuthContext);

  const [userName, setUserName] = useState<string>("");
  const [pwd, setPwd] = useState<string>("");
  const [error, setError] = useState<string>("");

  const signOut = async () => {
    try {
      await auth.signOut();
    } catch (e) {
      console.error(error);
    }
  };

  const downloadMinute = () => {
    if (itemToDownload != "") {
    }
  };

  const handleUserChanged = (newValue: string) => {
    setUserName(newValue);
  };

  const handlePWDChanged = (newValue: string) => {
    setPwd(newValue);
  };

  const signIn = async () => {
    try {
      await auth.signInWithEmailAndPassword(userName, pwd);
    } catch (e: any) {
      console.log(e.code);
      switch (e.code) {
        case "auth/invalid-login-credentials":
          setError("Invalid login credentials.");
          break;
        case "auth/too-many-requests":
          setError("Too many requests. Wait before retry.");
          break;
        case "auth-user-not-found":
          setError("User not found.");
          break;
        case "auth-wrong-password":
          setError("Incorrect password.");
          break;
        default:
          setError("An error occurred during sign-in.");
          break;
      }
    }
  };

  const handleItemClicked = (value: string, id: number) => {
    setMinuteToDownload(value);
    setIdSelected(id);

    switch (value) {
      case Minutes.KickoffCassino:
        setMinuteToDownloadPath(kickoffCassino);
        break;
      case Minutes.KickoffBologna:
        setMinuteToDownloadPath(kickoffBologna);
        break;
      case Minutes.KickoffCassinoWp3Introduction:
        setMinuteToDownloadPath(kickoffCassinoWp3Introduction);
        break;

      case Minutes.Presentazione_Maxfish_220424_Di_Lillo:
        setMinuteToDownloadPath(PresentazioneMaxfish220424DiLillo);
        break;
      case Minutes.Introduzione_Maxfish_220424_Antonelli:
        setMinuteToDownloadPath(Introduzione_Maxfish_220424_Antonelli);
        break;

      case Minutes.Presentazione_Maxfish_220424_UNIBO:
        setMinuteToDownloadPath(Presentazione_Maxfish_220424_UNIBO);
        break;
    }
  };

  const handleModelItemClicked = (value: string, id: number) => {
    setModelToDownload(value);
    setIdModelSelected(id);

    switch (value) {
      case Models.PowerPoint:
        setModelToDownloadPath(Template);
        break;
      case Models.Ack:
        setModelToDownloadPath(ack);
        break;
      case Models.Cup:
        setModelToDownloadPath(cup);
        break;
      case Models.LogoSVG:
        setModelToDownloadPath(logo);
        break;
      case Models.LogoPNG:
        setModelToDownloadPath(logoPng);
        break;
      case Models.Letter:
        setModelToDownloadPath(letterDoc);
        break;

      case Models.LetterZip:
        setModelToDownloadPath(letterZip);
        break;

      case Models.MUR_PNRR_PPT_FIRMA:
        setModelToDownloadPath(MUR_PNRR_PPT_FIRMA);
        break;

      case Models.PNRRPoster:
        setModelToDownloadPath(PNRRPoster);
        break;
      case Models.PNRRMURPresentazione:
        setModelToDownloadPath(PNRRMURPresentazione);
        break;
      case Models.PNRR_MUR_RELAZIONE:
        setModelToDownloadPath(PNRR_MUR_RELAZIONE);
        break;
      case Models.PNRComunicati:
        setModelToDownloadPath(PNRComunicati);
        break;
    }
  };

  const handleDocumentsItemClicked = (value: string, id: number) => {
    setDocumentsToDownload(value);
    setDocumentsSelected(id);

    switch (value) {
      case Documents.Proposal:
        setDocumentsToDownloadPath(proposal);
        break;
      case Documents.Annex2:
        setDocumentsToDownloadPath(annex2);
        break;
      case Documents.Roles:
        setDocumentsToDownloadPath(roles);
        break;
      case Documents.Poster:
        setDocumentsToDownloadPath(poster);
        break;

      case Documents.NotaChiarimenti:
        setDocumentsToDownloadPath(NotaChiarimenti);
        break;
      case Documents.LoghiItalia:
        setDocumentsToDownloadPath(LoghiItalia);
        break;
      case Documents.LoghiMur:
        setDocumentsToDownloadPath(LoghiMur);
        break;
      case Documents.LoghiNextGen:
        setDocumentsToDownloadPath(LoghiNextGen);
        break;
    }
  };

  const downloadSVG = () => {
    const blob = new Blob([logo], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "downloaded.svg";
    a.click();

    URL.revokeObjectURL(url);
  };

  return (
    <div
      className={`relative  w-full snap-start bg-cover  overflow-x-hidden ${
        isAboveSmallScreen
          ? `${getPageDimens()}  overflow-y-hidden`
          : "h-[calc(100vh-120px)] bg-cover "
      }`}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
      onMouseEnter={() => setSelectedDiv("private")}
    >
      {!user ? (
        <div
          className={`relative h-full w-full  flex flex-col  justify-center gap-10 ${
            isAboveSmallScreen ? " items-center" : "left-0 items-center"
          }`}
        >
          <div
            className={` ${
              isAboveSmallScreen ? "w-1/4" : "w-1/2"
            } gap-5 flex flex-col items-center justify-center`}
          >
            <div className={`text-white ${getTitle2Dimens()}`}>
              Private Area
            </div>
            <TextField
              label={"Username"}
              value={userName}
              onChange={handleUserChanged}
              type={"email"}
              id={"username"}
              page={true}
            />
            <TextField
              label={"Password"}
              value={pwd}
              onChange={handlePWDChanged}
              type={"password"}
              id={"pwd"}
              page={true}
            />
          </div>
          <div
            className={`  ${
              buttonHover
                ? ` p-1 text-center ${getButtonTextDimensHover()}`
                : `p-1 text-center ${getButtonTextDimens()}`
            } transition-width duration-300 ease-in-out `}
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
          >
            <button
              className=" px-10 py-1 font-semibold  bg-white text-blue-black "
              onClick={() => signIn()}
            >
              Sign In
            </button>
          </div>{" "}
          {error != "" && <div className=" text-white">{error}</div>}{" "}
          {isAboveSmallScreen ? (
            <div className="absolute bottom-5 right-5 w-40 h-40  flex flex-col justify-center items-center gap-4">
              <a href="https://www.mur.gov.it/it" target="_new">
                <div
                  className="w-12 h-12 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${MURLogo})`,
                  }}
                />
              </a>
              <div className={`text-xs text-white`}>20225RYMJE</div>
              <div
                className={`flex w-full  h-[calc(20px)] justify-center items-center gap-5`}
              >
                <img className="w-1/5" src={IT} />

                <div className="text-white w-4/5">Progetto PRIN</div>
              </div>
            </div>
          ) : (
            <div className="  flex flex-col justify-center items-center w-40 h-40 gap-4 pb-20 mt-10">
              {" "}
              <a href="https://www.mur.gov.it/it" target="_new">
                <div
                  className="w-12 h-12 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${MURLogo})`,
                  }}
                />
              </a>{" "}
              <div className={`text-xs text-white`}>20225RYMJE</div>
              <div
                className={`flex w-full  h-[calc(20px)] justify-center items-center gap-5`}
              >
                <img className="w-1/5" src={IT} />

                <div className="text-white w-4/5">Progetto PRIN</div>
              </div>
            </div>
          )}
        </div>
      ) : isAboveSmallScreen ? (
        <div className="w-full h-full flex flex-col justify-start items-start text-white mx-20 mt-10 gap-5">
          <div className="w-full h-1/3 flex  justify-start items-start text-white  ">
            <div className={` w-1/2 h-1/3 `}>
              <p>
                <span className={`${getSubtitleDimens()}`}>Next Meeting: </span>
                <span className={`${getSubtitleDimens()}`}>{nextMeeting}</span>
              </p>
              <p className="mt-5">
                <span className={`${getSubtitleDimens()}`}>Link: </span>

                <a href={googleLink}>
                  <span className={`${getSubtitleDimens()}`}>{googleLink}</span>
                </a>
              </p>{" "}
              <p className="mt-10">
                <span className={`${getSubtitleDimens()}`}>
                  Git repository:{" "}
                </span>

                <a href={repository}>
                  <span className={`${getSubtitleDimens()}`}>{repository}</span>
                </a>
              </p>{" "}
              <div
                className={`  ${
                  buttonHover
                    ? ` text-left ${getButtonTextDimensHover()}`
                    : ` text-left ${getButtonTextDimens()}`
                } transition-width duration-300 ease-in-out mt-10 `}
                onMouseEnter={() => setButtonHover(true)}
                onMouseLeave={() => setButtonHover(false)}
              >
                <button
                  className=" px-10 py-1 font-semibold  bg-white text-blue-black "
                  onClick={signOut}
                >
                  Sign out
                </button>
              </div>
            </div>
            <div className={` w-1/2 h-full flex flex-col `}>
              <span className={`${getSubtitleDimens()}`}>Meetings </span>

              <div className="border border-white border-solid  h-full w-2/3  mt-5 overflow-auto">
                {minuteItems.map((item, index) => (
                  <div
                    className={`px-2 w-full ${
                      idSelected == index
                        ? "bg-secondBlue bg-opacity-40"
                        : "bg-transparent"
                    } `}
                  >
                    <p
                      className="py-2 px-2 "
                      key={index}
                      onClick={() => handleItemClicked(item, index)}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>

              <div className={`${getButtonTextDimens()} mt-5`}>
                <a
                  href={minuteToDownloadPath}
                  target="_new"
                  download={minuteToDownload}
                >
                  <button
                    className=" px-10 py-1 font-semibold  bg-white text-blue-black "
                    onClick={() => {}}
                  >
                    Download
                  </button>
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="flex w-full h-1/2  mb-[calc(120px)] pr-20 gap-20">
            <div className={` w-1/2 h-full flex flex-col `}>
              <span className={`${getSubtitleDimens()}`}>Models </span>

              <div className="border border-white border-solid  h-full w-full mt-5 overflow-x-hidden overflow-y-auto ">
                {modelItems
                  .sort((a, b) => a.localeCompare(b)) // You can customize the sorting logic here
                  .map((item, index) => (
                    <div
                      className={`px-2 w-full ${
                        idModelSelected == index
                          ? "bg-secondBlue bg-opacity-40"
                          : "bg-transparent"
                      } `}
                    >
                      <p
                        className="py-2 px-2 "
                        key={index}
                        onClick={() => handleModelItemClicked(item, index)}
                      >
                        {item}
                      </p>
                    </div>
                  ))}
              </div>

              <div className={`${getButtonTextDimens()} mt-5`}>
                <a
                  href={modelToDownloadPath}
                  target="_new"
                  download={modelToDownload}
                >
                  <button className=" px-10 py-1 font-semibold  bg-white text-blue-black ">
                    Download
                  </button>
                </a>
              </div>
            </div>
            <div className={` w-1/2 h-full flex flex-col pr-40 `}>
              <span className={`${getSubtitleDimens()}`}>Documents </span>

              <div className="border border-white border-solid  h-full w-full mt-5 overflow-x-hidden overflow-y-auto ">
                {documentsItems
                  .sort((a, b) => a.localeCompare(b)) // You can customize the sorting logic here
                  .map((item, index) => (
                    <div
                      className={`px-2 w-full ${
                        idDocumentsSelected == index
                          ? "bg-secondBlue bg-opacity-40"
                          : "bg-transparent"
                      } `}
                    >
                      <p
                        className="py-2 px-2 "
                        key={index}
                        onClick={() => handleDocumentsItemClicked(item, index)}
                      >
                        {item}
                      </p>
                    </div>
                  ))}
              </div>

              <div className={`${getButtonTextDimens()} mt-5`}>
                <a
                  href={documentsToDownloadPath}
                  target="_new"
                  download={documentsToDownload}
                >
                  <button className=" px-10 py-1 font-semibold  bg-white text-blue-black ">
                    Download
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="absolute bottom-5 right-5 w-40 h-40  flex flex-col justify-center items-center gap-4">
            <a href="https://www.mur.gov.it/it" target="_new">
              <div
                className="w-12 h-12 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${MURLogo})`,
                }}
              />
            </a>
            <div className={`text-xs text-white`}>20225RYMJE</div>
            <div
              className={`flex w-full  h-[calc(20px)] justify-center items-center gap-5`}
            >
              <img className="w-1/5" src={IT} />

              <div className="text-white w-4/5">Progetto PRIN</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col justify-start items-center text-white px-10 mt-10 gap-10">
          <div className="w-full h-full flex flex-col  justify-start items-center text-white  gap-10">
            <div className={` w-full h-1/3 `}>
              <p>
                <span className={`${getSubtitleDimens()}`}>Next Meeting: </span>
                <span className={`${getSubtitleDimens()}`}>{nextMeeting}</span>
              </p>
              <p className="mt-5">
                <span className={`${getSubtitleDimens()}`}>Link: </span>

                <a href={googleLink}>
                  <span className={`${getSubtitleDimens()}`}>{googleLink}</span>
                </a>
              </p>{" "}
              <p className="mt-10">
                <span className={`${getSubtitleDimens()}`}>
                  Git repository:{" "}
                </span>

                <a href={repository}>
                  <span className={`${getSubtitleDimens()}`}>{repository}</span>
                </a>
              </p>{" "}
            </div>
            <div className={` w-full h-full flex flex-col items-center `}>
              <span className={`${getSubtitleDimens()} mt-10`}>
                Meetings minutes{" "}
              </span>

              <div className="border border-white border-solid  h-full w-full mt-5 overflow-auto">
                {minuteItems.map((item, index) => (
                  <div
                    className={`px-2 w-full ${
                      idSelected == index
                        ? "bg-secondBlue bg-opacity-40"
                        : "bg-transparent"
                    } `}
                  >
                    <p
                      className="py-2 px-2 "
                      key={index}
                      onClick={() => handleItemClicked(item, index)}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>

              <div className={`${getButtonTextDimens()} mt-5`}>
                <button
                  className=" px-10 py-1 font-semibold  bg-white text-blue-black "
                  onClick={() => {}}
                >
                  Download
                </button>
              </div>
            </div>
            <div className={` w-full h-full flex flex-col items-center `}>
              <span className={`${getSubtitleDimens()} mt-10`}>Models </span>

              <div className="border border-white border-solid  h-full w-full mt-5 overflow-auto">
                {modelItems
                  .sort((a, b) => a.localeCompare(b)) // You can customize the sorting logic here
                  .map((item, index) => (
                    <div
                      className={`px-2 w-full ${
                        idModelSelected == index
                          ? "bg-secondBlue bg-opacity-40"
                          : "bg-transparent"
                      } `}
                    >
                      <p
                        className="py-2 px-2 "
                        key={index}
                        onClick={() => handleModelItemClicked(item, index)}
                      >
                        {item}
                      </p>
                    </div>
                  ))}
              </div>

              <div className={`${getButtonTextDimens()} mt-5`}>
                {" "}
                <a
                  href={modelToDownloadPath}
                  target="_new"
                  download={modelToDownload}
                >
                  <button
                    className=" px-10 py-1 font-semibold  bg-white text-blue-black "
                    onClick={() => {}}
                  >
                    Download
                  </button>
                </a>
              </div>
            </div>
            <div className={` w-full h-full flex flex-col items-center `}>
              <span className={`${getSubtitleDimens()} mt-10`}>Documents </span>

              <div className="border border-white border-solid  h-full w-full mt-5 overflow-auto">
                {documentsItems
                  .sort((a, b) => a.localeCompare(b)) // You can customize the sorting logic here
                  .map((item, index) => (
                    <div
                      className={`px-2 w-full ${
                        idDocumentsSelected == index
                          ? "bg-secondBlue bg-opacity-40"
                          : "bg-transparent"
                      } `}
                    >
                      <p
                        className="py-2 px-2 "
                        key={index}
                        onClick={() => handleDocumentsItemClicked(item, index)}
                      >
                        {item}
                      </p>
                    </div>
                  ))}
              </div>

              <div className={`${getButtonTextDimens()} mt-5`}>
                {" "}
                <a
                  href={documentsToDownloadPath}
                  target="_new"
                  download={documentsToDownload}
                >
                  <button
                    className=" px-10 py-1 font-semibold  bg-white text-blue-black "
                    onClick={() => {}}
                  >
                    Download
                  </button>
                </a>
              </div>
            </div>
            <div
              className={`  ${
                buttonHover
                  ? ` text-left ${getButtonTextDimensHover()}`
                  : ` text-left ${getButtonTextDimens()}`
              } transition-width duration-300 ease-in-out mt-10 `}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
            >
              <button
                className=" px-10 py-1 font-semibold  bg-white text-blue-black "
                onClick={signOut}
              >
                Sign out
              </button>
            </div>
            <div className="  flex flex-col justify-center items-center w-40 h-40 gap-4 pb-20 mt-10">
              {" "}
              <a href="https://www.mur.gov.it/it" target="_new">
                <div
                  className="w-12 h-12 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${MURLogo})`,
                  }}
                />
              </a>{" "}
              <div className={`text-xs text-white`}>20225RYMJE</div>
              <div
                className={`flex w-full  h-[calc(20px)] justify-center items-center gap-5`}
              >
                <img className="w-1/5" src={IT} />

                <div className="text-white w-4/5">Progetto PRIN</div>
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </div>
  );
};

export default Private;

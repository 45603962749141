import React, { useState } from "react";
import "./App.css";
import { Screens, SelectedPage } from "./shared/types";
import useMediaQuery from "./hooks/useMediaQuery";
import Navbar from "./Components/NavBar";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import BannerImage from "./assets/background.png";
import BannerImage2 from "./assets/backgroundPrivate.png";
import Private from "./pages/home/components/private";
import Project from "./pages/project";
import ContactsPage from "./pages/contacts";
import PublicationsPage from "./pages/publication";
import Team from "./pages/home/components/team";
import TeamPage from "./pages/team";
import ProjectMobile from "./pages/project/indexMobile";
import Privacy from "./pages/privacy";

function App() {
  const [selectedPage, setSelectedPage] = useState<SelectedPage>(
    SelectedPage.Home
  );
  const [isMenuToggled, setIsMenuToggled] = useState<boolean>(false);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreens = useMediaQuery(Screens.Small);

  const [selectedDiv, setSelectedDiv] = useState<String>("home");
  const [previousDiv, setPreviousDiv] = useState<String>("home");
  const [overBar, setOverBar] = useState<boolean>(false);
  const [buttonHover, setButtonHover] = useState<boolean>(false);

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return " h-[calc(100vh-90px)]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-90px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return " h-[calc(100vh-60px)]";
    } else {
      return " h-[calc(100vh-60px)]";
    }
  }

  return (
    <div className={`scrollbar-hidden fixed top-0  w-full ${getPageDimens()} `}>
      <div
        className="flex h-screen w-full select-none flex-col  bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage:
            selectedPage == SelectedPage.Home
              ? `url(${BannerImage})`
              : `url(${BannerImage2})`,
          backgroundPosition: isAboveSmallScreens ? "" : "center",
        }}
      >
        <div
          className="flex h-screen w-full select-none flex-col  bg-no-repeat bg-center bg-cover bg-blue-black bg-opacity-60"
          style={{}}
        >
          <div className={` z-50 bg-ash`}>
            <Navbar
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              isMenuToggled={isMenuToggled}
              setIsMenuToggled={setIsMenuToggled}
              selectedDiv={selectedDiv}
              setSelectedDiv={setSelectedDiv}
            />
          </div>
          <div className="flex flex-1 select-none flex-row overflow-y-hidden">
            <Routes>
              <Route
                path="/"
                element={
                  <HomePage
                    buttonHover={buttonHover}
                    setButtonHover={setButtonHover}
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    previousDiv={previousDiv}
                    setPreviousDiv={setPreviousDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
              <Route
                path="/home"
                element={
                  <HomePage
                    buttonHover={buttonHover}
                    setButtonHover={setButtonHover}
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    previousDiv={previousDiv}
                    setPreviousDiv={setPreviousDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
              <Route
                path="/project"
                element={
                  isAboveSmallScreens ? (
                    <Project
                      buttonHover={buttonHover}
                      setButtonHover={setButtonHover}
                      isMenuToggled={isMenuToggled}
                      setIsMenuToggled={setIsMenuToggled}
                      selectedDiv={selectedDiv}
                      setSelectedDiv={setSelectedDiv}
                      previousDiv={previousDiv}
                      setPreviousDiv={setPreviousDiv}
                      setSelectedPage={setSelectedPage}
                    />
                  ) : (
                    <ProjectMobile
                      buttonHover={buttonHover}
                      setButtonHover={setButtonHover}
                      isMenuToggled={isMenuToggled}
                      setIsMenuToggled={setIsMenuToggled}
                      selectedDiv={selectedDiv}
                      setSelectedDiv={setSelectedDiv}
                      previousDiv={previousDiv}
                      setPreviousDiv={setPreviousDiv}
                      setSelectedPage={setSelectedPage}
                    />
                  )
                }
              />{" "}
              <Route
                path="/publications"
                element={
                  <PublicationsPage
                    buttonHover={buttonHover}
                    setButtonHover={setButtonHover}
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    previousDiv={previousDiv}
                    setPreviousDiv={setPreviousDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
              <Route
                path="/team"
                element={
                  <TeamPage
                    buttonHover={buttonHover}
                    setButtonHover={setButtonHover}
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    previousDiv={previousDiv}
                    setPreviousDiv={setPreviousDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
              <Route
                path="/contacts"
                element={
                  <ContactsPage
                    buttonHover={buttonHover}
                    setButtonHover={setButtonHover}
                    isMenuToggled={isMenuToggled}
                    setIsMenuToggled={setIsMenuToggled}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                    previousDiv={previousDiv}
                    setPreviousDiv={setPreviousDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
              <Route
                path="/private"
                element={
                  <Private
                    buttonHover={buttonHover}
                    setButtonHover={setButtonHover}
                    selectedDiv={selectedDiv}
                    setSelectedDiv={setSelectedDiv}
                  />
                }
              />
              <Route
                path="/privacyPolicy"
                element={
                  <Privacy
                    buttonHover={buttonHover}
                    setButtonHover={setButtonHover}
                    selectedDiv={selectedDiv}
                    setSelectedPage={setSelectedPage}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

export enum SelectedPage {
  Home = "home",
  Project = "project",
  Team = "team",
  Publications = "publications",
  Contacts = "contacts",
  Private = "private",
  Privacy = "privacy"
}

export enum Screens {
  Huge = "(min-width: 2550px) and (min-height: 1430)",
  Large = "(min-width: 2210px) and (min-height: 1070px)",
  Medium = "(min-width: 1366px) and (min-height: 768px)",
  Small = "(min-width: 900px) and  (min-height: 640px)",
}

export enum Models {
  PowerPoint = "PowerPoint template.pptx",
  Ack = "Acknowledgement template.txt",
  Cup = "CUP.txt",
  LogoSVG = "Logo.svg",
  LogoPNG = "Logo.png",
  Letter = "Letter.docx",
  LetterZip = "Latex Letter.zip",
  MUR_PNRR_PPT_FIRMA = "MUR_PNRR_PPT_Loghi Firma.pptx",
  PNRRPoster = "PNRR_MUR_Poster_A3.pptx",
  PNRRMURPresentazione = "PNRR_MUR_Presentazione power point.pptx",
  PNRR_MUR_RELAZIONE = "PNRR_MUR_Relazione.docx",
  PNRComunicati = "PNRR_MUR_Comunicato generale_BLU_loghi header.docx",
}

export enum Minutes {
  KickoffCassino = "061023_kickoff_Cassino.pdf",
  KickoffBologna = "061023_kickoff_Bologna.pdf",
  KickoffCassinoWp3Introduction = "241123_kickoff_cassino_wp3_introduction.pdf",
  Presentazione_Maxfish_220424_Di_Lillo = "220424_Presentazione_Maxfish_Di_Lillo.pptx",
  Introduzione_Maxfish_220424_Antonelli = "220424_Introduzione_Maxfish_Antonelli.pdf",
  Presentazione_Maxfish_220424_UNIBO = "220424_Presentazione_Maxfish_UNIBO.pptx"
}

export enum Documents {
  Proposal = "Proposal.pdf",
  Annex2 = "DD n. 104, Annex 2.pdf",
  Roles = "Roles.xlsx",
  Poster = "Poster.pdf",
  NotaChiarimenti = "NOTA CHIARIMENTI COMUNICAZIONE.0026278.28-12-2023.pdf",
  LoghiItalia = "Loghi Italiadomani.zip",
  LoghiMur = "Loghi MUR.zip",
  LoghiNextGen = "Loghi NextGenEU.zip",
}
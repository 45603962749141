import React, { useState } from "react";
import { Transition } from "@headlessui/react";

type Props = {
  children: React.ReactNode;
  clicked: boolean;
};

const AnimatedOverline = ({ children, clicked }: Props) => {
  const [show, setShow] = useState(false);

  const shouldAnimate = !clicked && show;

  const line = "absolute inset-0 -top-1 bg-white h-1 transform";


  return (
    <span
      className="relative inline-block"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Transition
        show={shouldAnimate}
        enter="transition ease-in-out duration-200 transform"
        enterFrom="scale-x-0"
        enterTo="scale-x-100"
        leave="transition ease-in-out duration-200 transform"
        leaveFrom="scale-x-100"
        leaveTo="scale-x-0"
      >
        <span className={`${line}`}></span>
      </Transition>
      <span className={`${clicked ? line : "absolute inset-0 -top-1  transform"}`}></span>
      {children}
    </span>
  );
};
export default AnimatedOverline;

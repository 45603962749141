import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import BannerImage from "../../../assets/background3.png";
import Footer from "../../../Components/Footer";
import Unicas from "../../../assets/unicas.png";
import Univpm from "../../../assets/univpm.png";
import Unibo from "../../../assets/unibo.png";
import MurLogo from "../../../assets/mur.png";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;
  selectedDiv: String;
  scrollToAbout: () => void;
  setSelectedPage: (value: SelectedPage) => void;
};

const Contacts = ({
  buttonHover,
  setButtonHover,
  selectedDiv,
  scrollToAbout,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  return (
    <div
      className={`relative  w-full snap-start bg-cover bg-center ${
        isAboveSmallScreen
          ? `${getPageDimens()}`
          : "h-[calc(100vh-120px)] bg-cover "
      }`}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      {" "}
      {/* {selectedDiv === "home" && <MouseParticles />}*/}
      <div
        className={`absolute  top-0 bottom-20 flex flex-col right-0 justify-center gap-10 ${
          isAboveSmallScreen ? " left-0  items-center" : "left-0 items-center"
        }`}
      >
        <div
          className={` h-full w-full flex flex-col justify-center gap-10 items-center `}
        >
          <div className={`${getTitleDimens()} text-white text-center`}>
            Contacts
          </div>
          <p className={`text-white ${getSubtitleDimens()}`}>
            Have questions, feedback, or just want to get in touch? We'd love to
            hear from you!
          </p>
          <div className="flex items-center justify-center w-full h-1/3">
            <a
              className="w-1/4 h-full flex flex-col items-center justify-center"
              href="https://www.unicas.it"
              target="_new"
            >
              <div
                className="w-full h-full bg-contain bg-no-repeat bg-center mt-10"
                style={{ backgroundImage: `url(${Unicas})` }}
              />{" "}
              <div className={`text-white mt-5 w-full h-full text-center`}>
                Università degli Studi di Cassino e del Lazio Meridionale
              </div>
            </a>
            <a
              className="w-1/4 h-full flex flex-col items-center justify-center"
              href="https://www.univpm.it/"
              target="_new"
            >
              <div
                className="w-full h-full bg-contain bg-no-repeat bg-center mt-10"
                style={{ backgroundImage: `url(${Univpm})` }}
              />{" "}
              <div className={`text-white mt-5 w-full h-full text-center`}>
                Università Politecnica delle Marche
              </div>
            </a>
            <a
              className="w-1/4 h-full flex flex-col items-center justify-center"
              href="https://www.unibo.it/it/"
              target="_new"
            >
              <div
                className="w-full h-full bg-contain bg-no-repeat bg-center mt-10"
                style={{ backgroundImage: `url(${Unibo})` }}
              />{" "}
              <div className={`text-white mt-5 w-full h-full text-center`}>
                Università di Bologna
              </div>
            </a>
          </div>

          <div className="text-white text-xl text-center">
            <p><a href="mailto:antonelli@unicas.it" >antonelli@unicas.it</a></p>
            <p><a href="mailto:d.scaradozzi@univpm.it" >d.scaradozzi@univpm.it</a></p>
            <p><a href="mailto:elena.zattoni@unibo.it" >elena.zattoni@unibo.it</a></p>
          </div>
        </div>
      </div>{" "}
      {/*<div className=" absolute bottom-10 right-20 flex flex-col justify-center items-center gap-4">
        <a href="https://www.mur.gov.it/it" target="_new">
          <div
            className="w-12 h-12 bg-cover bg-center"
            style={{
              backgroundImage: `url(${MurLogo})`,
            }}
          />
        </a>
        <div className="text-white">Progetto PRIN</div>
          </div>{" "}*/}
      <div className="absolute bottom-0 w-full">
        <Footer setSelectedPage={setSelectedPage} />
      </div>
    </div>
  );
};

export default Contacts;

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


const firebaseConfig = {
  apiKey: "AIzaSyCYGp_0MfEKOXk_nbroU36c9arO4tU_KKU",
  authDomain: "maxfish-522f9.firebaseapp.com",
  projectId: "maxfish-522f9",
  storageBucket: "maxfish-522f9.appspot.com",
  messagingSenderId: "54809639282",
  appId: "1:54809639282:web:644d98fc0a2e7711a045eb",
  measurementId: "G-S8ZHWPZRGG"
};


firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();


import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens } from "../../../shared/types";
import MURLogo from "../../../assets/mur.png";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;
  selectedDiv: String;
  scrollToAbout: () => void;
};

const Home = ({
  buttonHover,
  setButtonHover,
  selectedDiv,
  scrollToAbout,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen) {
      return "text-3xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  return (
    <div
      className={`relative  w-full snap-start bg-cover bg-center ${
        isAboveSmallScreen
          ? `${getPageDimens()}`
          : "h-[calc(100vh-120px)] bg-cover "
      }`}
    >
      {" "}
      {/* {selectedDiv === "home" && <MouseParticles />}*/}
      <div
        className={`absolute  top-0 bottom-20 flex flex-col right-0 justify-center gap-10 ${
          isAboveSmallScreen ? " left-0  items-center" : "left-0 items-center"
        }`}
      >
        <div className={` h-2/4 w-3/4 flex flex-col justify-center gap-10  `}>
          <div className={`${getTitleDimens()} text-white text-center`}>
            Welcome
          </div>
          <div
            className={`${getSubtitleDimens()} text-white ml-[calc(10px)] text-center`}
          >
            <p>
              {" "}
              Multi agents systems and Max-Plus algebra theoretical frameworks
            </p>
            <p>for a robot-fish shoal modelling and control </p>
          </div>
          <div
            className={`  ${
              buttonHover
                ? ` p-1 text-center ${getButtonTextDimensHover()}`
                : `p-1 text-center ${getButtonTextDimens()}`
            } transition-width duration-300 ease-in-out ml-[calc(10px)]`}
            onMouseEnter={() => setButtonHover(true)}
            onMouseLeave={() => setButtonHover(false)}
          >
            <button
              className=" px-10 py-1 font-semibold  bg-white text-blue-black "
              onClick={scrollToAbout}
            >
              explore
            </button>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Home;

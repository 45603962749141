import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import BannerImage from "../../../assets/background3.png";
import Footer from "../../../Components/Footer";
import Unicas from "../../../assets/unicas.png";
import EB from "../../../assets/eb_circle.png";
import Unibo from "../../../assets/unibo.png";
import Univpm from "../../../assets/univpm.png";
import Test from "../../../assets/test.png";
import DiVito from "../../../assets/divito.jpeg";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;

  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;

  previousDiv: String;
  setPreviousDiv: (value: String) => void;

  setSelectedPage(value: SelectedPage): void;
};

const DiVitoMobile = ({
  buttonHover,
  setButtonHover,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
  previousDiv,
  setPreviousDiv,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className="w-full h-full snap-start"
      id="cassino"
      onMouseEnter={() => {
        setPreviousDiv(selectedDiv);
        setSelectedDiv("cassino");
      }}
      ref={ref}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      <div
        className={`relative  w-full h-full bg-cover bg-center flex flex-col items-center justify-start pt-[calc(20px)]  px-10 gap-10`}
      >
        <div className={`text-white ${getTitleDimens()}`}>Team</div>
        <div className="w-full h-full flex flex-col items-center justify-start">
          <div
            className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
            style={{ backgroundImage: `url(${DiVito})` }}
          />
          <div className={`text-white ${getSubtitleDimens()} mt-5`}>
            Daniele Di Vito
          </div>
          <div
            className="w-full h-1/5 bg-contain bg-no-repeat bg-center mt-10"
            style={{ backgroundImage: `url(${EB})` }}
          />{" "}
          <div className={`text-white mt-5`}>
            {" "}
            <a href="https://www.everybotics.it/" target="_new">
              EveryBotics
            </a>
          </div>
          <div className="text-white">
            <a href="mailto:daniele.divito@everybotics.it">
              {" "}
              daniele.divito@everybotics.it
            </a>{" "}
          </div>
          <div className={`text-white `}>
            <a href="https://www.danieledivitoengineering.it/" target="_new">
              Read more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiVitoMobile;

import { useRef } from "react";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import ScrollToTopButton from "../../Components/ScrollToTopButton";
import BannerImage from "../../assets/backgroundPrivate.png";
import Test from "../../assets/test.png";
import Footer from "../../Components/Footer";
import FooterMain from "../../Components/FooterMain";
import Contacts from "../home/components/contacts";
import ContactsMobile from "../home/components/mobile/contacts";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;

  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;

  previousDiv: String;
  setPreviousDiv: (value: String) => void;

  setSelectedPage(value: SelectedPage): void;
};

function ContactsPage({
  buttonHover,
  setButtonHover,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
  previousDiv,
  setPreviousDiv,
  setSelectedPage,
}: Props) {
  const aboutRef = useRef<HTMLDivElement>(null);
  const isAboveSmallcreens = useMediaQuery(Screens.Small);

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen) {
      return "text-3xl";
    } else if (isAboveSmallScreen) {
      return "text-2xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg text-center";
    }
  }

  return (
    <main
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="no-scrollbar flex-1 snap-y snap-mandatory    overflow-y-auto overflow-x-hidden"
    >
      {" "}
      <div
        className={`${isAboveSmallScreen ? "" : "mt-10"}`}
        id="contacts"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("contacts");
        }}
      >
        {isAboveSmallScreen ? (
          <Contacts
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            setSelectedPage={setSelectedPage}
            scrollToAbout={scrollToAbout}
          />
        ) : (
          <ContactsMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            setSelectedPage={setSelectedPage}
            scrollToAbout={scrollToAbout}
          />
        )}
      </div>
    </main>
  );
}

export default ContactsPage;

import { useRef } from "react";
import { Screens, SelectedPage } from "../../shared/types";
import Home from "./components/home";
import useMediaQuery from "../../hooks/useMediaQuery";
import ScrollToTopButton from "../../Components/ScrollToTopButton";
import Project from "./components/project";
import Team from "./components/team";
import Publication from "./components/publications";
import Contacts from "./components/contacts";

import MURLogo from "../../assets/mur.png";
import HomeMobile from "./components/mobile/home";
import { useLocation } from "react-router-dom";
import React from "react";
import ProjectMobile from "./components/mobile/project";
import TeamMobile from "./components/mobile/team";
import PublicationsMobile from "./components/mobile/publications";
import ContactsMobile from "./components/mobile/contacts";

import IT from "../../assets/flags/it.png";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;

  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;

  previousDiv: String;
  setPreviousDiv: (value: String) => void;

  setSelectedPage(value: SelectedPage): void;
};

function HomePage({
  buttonHover,
  setButtonHover,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
  previousDiv,
  setPreviousDiv,
  setSelectedPage,
}: Props) {
  const aboutRef = useRef<HTMLDivElement>(null);
  const isAboveSmallcreens = useMediaQuery(Screens.Small);

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function getMurWidth(): string {
    if (isAboveSmallcreens) {
      return "w-24 h-24";
    } else {
      return "w-16 h-16";
    }
  }

  function getWritesWidth(): string {
    if (isAboveSmallcreens) {
      return "w-44";
    } else {
      return "w-24";
    }
  }

  const history = useLocation();

  // Update selectedPage state when the route changes
  React.useEffect(() => {
    console.log(history.pathname);

    setSelectedPage(SelectedPage.Home);
  }, [history]);

  return (
    <main
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="no-scrollbar flex-1 snap-y snap-mandatory overflow-y-auto overflow-x-hidden"
    >
      <div
        className=""
        id="home"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("home");
        }}
        ref={ref}
      >
        {isAboveSmallcreens ? (
          <Home
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
          />
        ) : (
          <HomeMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
          />
        )}
      </div>
      <div
        className=""
        id="project"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("project");
        }}
        ref={aboutRef}
      >
        {" "}
        {isAboveSmallcreens ? (
          <Project
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ProjectMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>
      <div
        className=""
        id="team"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("team");
        }}
      >
        {isAboveSmallcreens ? (
          <Team
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <TeamMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div
        className=""
        id="publications"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("publications");
        }}
      >
        {isAboveSmallcreens ? (
          <Publication
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <PublicationsMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>
      <div
        className=""
        id="contacts"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("contacts");
        }}
      >
        {isAboveSmallcreens ? (
          <Contacts
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
            setSelectedPage={setSelectedPage}
          />
        ) : (
          <ContactsMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            selectedDiv={selectedDiv}
            scrollToAbout={scrollToAbout}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>

      <div
        className={`${
          selectedDiv === "home" || isMenuToggled ? "opacity-0" : "opacity-100"
        } transition-opacity duration-300 ease-in-out `}
        onClick={scrollToRef}
      >
        <ScrollToTopButton selectedDiv={selectedDiv} />
      </div>
      <div
        className={`absolute ${
          isAboveSmallcreens
            ? selectedDiv == "contacts"
              ? "bottom-[calc(60px)]"
              : "bottom-20"
            : "top-20"
        } ${
          isAboveSmallcreens ? "right-5 " : "left-5"
        } w-40 h-[calc(60px)] flex items-start `}
      >
        {selectedDiv != "contacts" && (
          <div className=" flex flex-col justify-center items-center gap-4 ">
            <a href="https://www.mur.gov.it/it" target="_new">
              <div
                className={`${getMurWidth()} bg-cover bg-center`}
                style={{
                  backgroundImage: `url(${MURLogo})`,
                }}
              />
            </a>
            {isAboveSmallcreens && (
              <div className={` ${getMurWidth()}  h-[calc(20px)] text-white`}>
                20225RYMJE
              </div>
            )}
            {isAboveSmallcreens && (
              <div
                className={`flex ${getWritesWidth()}  h-[calc(20px)] justify-center items-center gap-5`}
              >
                <img className="w-1/5" src={IT} />

                <div className="text-white w-4/5">Progetto PRIN</div>
              </div>
            )}
          </div>
        )}
      </div>
    </main>
  );
}

export default HomePage;

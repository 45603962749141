import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../shared/types";
import MURLogo from "../../assets/mur.png";
import BannerImage from "../../assets/background3.png";
import Footer from "../../Components/Footer";
import { NavLink } from "react-router-dom";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;
  selectedDiv: String;
  setSelectedPage: (value: SelectedPage) => void;
};

const Privacy = ({
  buttonHover,
  setButtonHover,
  selectedDiv,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  const date = "09/26/2023";
  const contactMail = "antonelli@unicas.it";

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getTitle2Dimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen) {
      return "text-4xl";
    } else if (isAboveSmallScreen) {
      return "text-3l";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  return (
    <div
      className={`relative  w-full  bg-cover bg-center overflow-y-auto overflow-x-hidden`}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      <div
        className={`flex h-full w-full flex-col  justify-start text-white mt-10 text-justify ${
          isAboveSmallScreen ? "items-start px-20" : "items-center px-5"
        } `}
      >
        <div className={`${getTitleDimens()}`}>Privacy Policy</div>
        <div className={`${getSubtitleDimens()} mt-10`}>
          Effective Date: {date}
        </div>
        <div className="mt-10">
          Thank you for visiting the MAXFISH Project website. At MAXFISH, we are
          committed to protecting your privacy and ensuring the security of your
          personal information. This Privacy Policy outlines our practices
          regarding the collection, use, and protection of any information
          provided or collected through this website.
        </div>
        <div className="mt-10 font-bold">1. Information Collection</div>
        <div className="mt-10">
          We want to assure you that MAXFISH does not collect any personal
          information from visitors to our website, except as explicitly
          described below:
        </div>
        <div className="mt-10 font-bold">1.1 Partners Login:</div>
        <div className="mt-10">
          We provide a login form for our partners on this website. However, we
          do not collect any personal information from you during the account
          creation or login process. MAXFISH creates and manages partner
          accounts independently, and these accounts do not require you to input
          any personal information. You will be provided with login credentials
          by our team, and it is your responsibility to keep these credentials
          confidential.
        </div>{" "}
        <div className="mt-10 font-bold">2. Third-Party Links</div>
        <div className="mt-10">
          Our website may contain links to third-party websites. Please note
          that MAXFISH is not responsible for the privacy practices or content
          of these external sites. We encourage you to review the privacy
          policies of these third-party websites before providing any personal
          information.
        </div>
        <div className="mt-10 font-bold">3. Security</div>
        <div className="mt-10">
          MAXFISH takes reasonable measures to protect the security of your
          information. However, since we do not collect personal information
          through this website, your account information is the responsibility
          of your organization, and you are responsible for maintaining its
          security.
        </div>
        <div className="mt-10 font-bold">4. Changes to this Privacy Policy</div>
        <div className="mt-10">
          MAXFISH may update this Privacy Policy from time to time to reflect
          changes in our practices or for other operational, legal, or
          regulatory reasons. We encourage you to review this Privacy Policy
          periodically to stay informed about how we are protecting your
          information.
        </div>
        <div className="mt-10 font-bold">5. Contact Us</div>
        <div className="mt-10">
          If you have any questions, concerns, or requests related to this
          Privacy Policy or our data practices, please contact us at{" "}
          <a href={contactMail}>{contactMail}</a>.
        </div>
        <div className="mt-10">
          By using the MAXFISH Project website, you acknowledge that you have
          read and agree to the terms of this Privacy Policy.
        </div>
        <div className="mt-10">Last Updated: {date}</div>{" "}
        <div className="mt-10 pb-40">MAXFISH</div>
      </div>
      
    </div>
  );
};

export default Privacy;

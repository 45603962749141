import { NavLink } from "react-router-dom";
import useMediaQuery from "../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../shared/types";

import MURLogo from "../assets/mur.png";
import IT from "../assets/flags/it.png";

type Props = {
  setSelectedPage: (value: SelectedPage) => void;
};

const Footer = ({ setSelectedPage }: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getPIVADimens(): String {
    if (isAboveHugeScreen) {
      return "text-base";
    } else if (isAboveLargeScreen) {
      return "text-base";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-sm";
    }
  }
  function getPolicyDimens(): String {
    if (isAboveHugeScreen) {
      return "text-sm";
    } else if (isAboveLargeScreen) {
      return "text-sm";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-sm";
    } else {
      return "text-xs";
    }
  }

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return "h-whiteBorderL";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "h-whiteBorderM";
    } else {
      return "h-whiteBorderS";
    }
  }

  return (
    <footer className={`z-40 w-full  ${getHeight()} bg-ash`}>
      {" "}
      <div className="absolute  top-0 left-[calc(40px)]  flex  justify-center items-center gap-4">
        {" "}
        <a href="https://www.mur.gov.it/it" target="_new">
          <div
            className=" h-[calc(60px)] w-[calc(60px)] bg-cover bg-center"
            style={{
              backgroundImage: `url(${MURLogo})`,
            }}
          />
        </a>{" "}
        <div
          className={`flex flex-col  h-[calc(20px)] justify-center items-start `}
        >
          <img className="w-12" src={IT} />
          <div className="text-white">Progetto PRIN</div>
        </div>
      </div>
      <NavLink
        className={`absolute right-[calc(60px)] bottom-[calc(35px)] text-white ${getPolicyDimens()} `}
        to={`/privacyPolicy`}
        onClick={() => {
          setSelectedPage(SelectedPage.Privacy);
        }}
      >
        <button className="text-white">Privacy Policy</button>
      </NavLink>
    </footer>
  );
};

export default Footer;

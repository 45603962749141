import Link from "../menu/Link";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import { NavLink } from "react-router-dom";
type Props = {
  isMenuToggled: boolean;
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  style?: React.CSSProperties;
  setIsMenuToggled: (value: boolean) => void;
};

const MenuPage = ({
  isMenuToggled,
  selectedPage,
  setSelectedPage,
  style,
  setIsMenuToggled,
}: Props) => {
  const isAboveMediumScreens = useMediaQuery(Screens.Small);

  return (
    <div
      style={style}
      className={`fixed right-0 top-0 z-50 flex h-full w-full origin-top-right transform
                     bg-blue-black transition-all duration-500 ${
                       isMenuToggled ? "scale-100" : "scale-0"
                     }`}
    >
      <div
        className={` flex items-center justify-center ${
          isAboveMediumScreens ? "w-3/4" : "w-full flex-col"
        }`}
      >
        <div
          className={`flex flex-col  justify-center gap-3 ${
            isAboveMediumScreens ? "items-start" : "items-center"
          }`}
        >
          <Link
            page="HOME"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
          <Link
            page="PROJECT"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
          <Link
            page="TEAM"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
          <Link
            page="PUBLICATIONS"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />

          <Link
            page="CONTACTS"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
          <Link
            page="PRIVATE"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            setIsMenuToggled={setIsMenuToggled}
          />
        </div>
        {!isAboveMediumScreens && (
          <div className="absolute bottom-10 flex flex-col items-center justify-center gap-4 text-center text-sm text-ash ">
            <div className="flex flex-col items-center justify-center gap-4 text-center text-sm text-ash">
              <div className="text-ash"> </div>
              <NavLink
                className={` text-xs}`}
                to={`/privacyPolicy`}
                onClick={() => {
                  setSelectedPage(SelectedPage.Privacy);
                  setIsMenuToggled(false);
                }}
              >
                <button>Privacy Policy</button>
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuPage;

import { useRef } from "react";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import ScrollToTopButton from "../../Components/ScrollToTopButton";
import BannerImage from "../../assets/backgroundPrivate.png";
import Test from "../../assets/test.png";
import Footer from "../../Components/Footer";
import FooterMain from "../../Components/FooterMain";

import Unicas from "../../assets/unicas.png";
import Univpm from "../../assets/univpm.png";
import Unibo from "../../assets/unibo.png";
import MurLogo from "../../assets/mur.png";
import Bologna from "./components.tsx/bologna";
import BolognaMobile from "./components.tsx/bolognaMobile";
import CassinoMobile from "./components.tsx/cassinoMobile";
import MarcheMobile from "./components.tsx/marcheMobile";
import PageTeam from "./components.tsx/page";
import ArrichielloMobile from "./components.tsx/arrichiello";
import MarinoMobile from "./components.tsx/marino";
import DiLilloMobile from "./components.tsx/dilillo";
import BartolucciMobile from "./components.tsx/bartolucci";
import ScrepantiMobile from "./components.tsx/screpanti";
import DiVitoMobile from "./components.tsx/divito";
import GilliniMobile from "./components.tsx/gillini";
import CastagnaMobile from "./components.tsx/castagnaMobile";
import DiNardoMobile from "./components.tsx/dinardo";
import CiuccoliMobile from "./components.tsx/ciuccoli";
import CostaMobile from "./components.tsx/costa";
import FiaraMobile from "./components.tsx/fiara";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;

  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;

  previousDiv: String;
  setPreviousDiv: (value: String) => void;

  setSelectedPage(value: SelectedPage): void;
};

function TeamPage({
  buttonHover,
  setButtonHover,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
  previousDiv,
  setPreviousDiv,
  setSelectedPage,
}: Props) {
  const aboutRef = useRef<HTMLDivElement>(null);
  const isAboveSmallcreens = useMediaQuery(Screens.Small);

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen) {
      return "text-3xl";
    } else if (isAboveSmallScreen) {
      return "text-2xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg text-center";
    }
  }
  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  return (
    <main
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="no-scrollbar flex-1  snap-y snap-mandatory    overflow-y-auto overflow-x-hidden"
    >
      {isAboveSmallScreen ? (
        <PageTeam
          buttonHover={buttonHover}
          setButtonHover={setButtonHover}
          isMenuToggled={isMenuToggled}
          setIsMenuToggled={setIsMenuToggled}
          selectedDiv={selectedDiv}
          setSelectedDiv={setSelectedDiv}
          previousDiv={previousDiv}
          setPreviousDiv={setPreviousDiv}
          setSelectedPage={setSelectedPage}
        />
      ) : (
        <div
          className="h-full w-full"
          id="cassino"
          ref={ref}
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("cassino");
          }}
        >
          <CassinoMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="marche"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("marche");
          }}
        >
          <MarcheMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="bologna"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("bologna");
          }}
        >
          <BolognaMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="arrichiello"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("arrichiello");
          }}
        >
          <ArrichielloMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="marino"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("marino");
          }}
        >
          <MarinoMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="dilillo"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("dilillo");
          }}
        >
          <DiLilloMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="castagna"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("castagna");
          }}
        >
          <CastagnaMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}

      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="dinardo"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("dinardo");
          }}
        >
          <DiNardoMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="ciuccoli"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("ciuccoli");
          }}
        >
          <CiuccoliMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="bartolucci"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("bartolucci");
          }}
        >
          <BartolucciMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="costa"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("costa");
          }}
        >
          <CostaMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
       {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="fiara"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("fiara");
          }}
        >
          <FiaraMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="divito"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("divito");
          }}
        >
          <DiVitoMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className="h-full w-full"
          id="gillini"
          onMouseEnter={() => {
            setPreviousDiv(selectedDiv);
            setSelectedDiv("gillini");
          }}
        >
          <GilliniMobile
            buttonHover={buttonHover}
            setButtonHover={setButtonHover}
            isMenuToggled={isMenuToggled}
            setIsMenuToggled={setIsMenuToggled}
            selectedDiv={selectedDiv}
            setSelectedDiv={setSelectedDiv}
            previousDiv={previousDiv}
            setPreviousDiv={setPreviousDiv}
            setSelectedPage={setSelectedPage}
          />
        </div>
      )}
      {!isAboveSmallScreen && (
        <div
          className={`${
            selectedDiv === "cassino" || isMenuToggled
              ? "opacity-0"
              : "opacity-100"
          } transition-opacity duration-300 ease-in-out `}
          onClick={scrollToRef}
        >
          <ScrollToTopButton selectedDiv={selectedDiv} />
        </div>
      )}
    </main>
  );
}

export default TeamPage;

import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import MURLogo from "../../../assets/mur.png";
import BannerImage from "../../../assets/background2.png";
import MySVGComponent from "../../../Components/MapSvgComponent";
import CustomDialog from "../../../Components/CustomDialog";
import { NavLink } from "react-router-dom";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;
  selectedDiv: String;
  scrollToAbout: () => void;
  setSelectedPage: (value: SelectedPage) => void;
};

const Team = ({
  buttonHover,
  setButtonHover,
  selectedDiv,
  scrollToAbout,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getTitle2Dimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen) {
      return "text-4xl";
    } else if (isAboveSmallScreen) {
      return "text-3l";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  const [svgWidth, setSvgWidth] = useState<string>("100%");
  const [svgHeight, setSvgHeight] = useState<string>("100%");

  useEffect(() => {
    // Calculate the aspect ratio of your SVG (e.g., width divided by height)
    const svgAspectRatio = 3072 / 1728; // Replace with your SVG's actual aspect ratio

    // Get the dimensions of the container div
    const containerWidth = window.innerWidth; // You can customize this as needed
    const containerHeight = window.innerHeight; // You can customize this as needed

    // Determine whether to scale width or height to cover the container
    if (containerWidth / containerHeight > svgAspectRatio) {
      // Scale based on width to cover the container
      setSvgWidth("100%");
      setSvgHeight(`${containerWidth / svgAspectRatio}px`);
    } else {
      // Scale based on height to cover the container
      setSvgWidth(`${containerHeight * svgAspectRatio}px`);
      setSvgHeight("100%");
    }
  }, []);

  const [hoveredCircle, setHoveredCircle] = useState<number | null>(null);
  const [cx, setCx] = useState<number | null>(null);
  const [cy, setCy] = useState<number | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleCircleOver = (index: number, cx: number, cy: number) => {
    setHoveredCircle(index);
    setCx(cx);
    setCy(cy);
  };

  const handleCloseDialog = () => {
    setHoveredCircle(null);
    setDialogOpen(false);
  };

  return (
    <div
      className={`relative  w-full snap-start bg-cover bg-center overflow-y-hidden bg-secondBlue ${
        isAboveSmallScreen
          ? `${getPageDimens()}`
          : "h-[calc(100vh-120px)] bg-cover "
      }`}
      style={{ backgroundImage: `url(${BannerImage})` }}
    >
      <div className="absolute top-0 left-0 bottom-0 right-0 w-full h-full  bg-opacity-0">
        <MySVGComponent
          style={{ width: svgWidth, height: svgHeight }}
          onCircleOver={handleCircleOver}
          setDialogOpen={setDialogOpen}
        />
        <CustomDialog
          isOpen={isDialogOpen}
          onClose={handleCloseDialog}
          circleIndex={hoveredCircle || 0}
          cx={cx!}
          cy={cy!}
          svgWidth={svgWidth}
          svgHeight={svgHeight}
        />
      </div>
      {/* {selectedDiv === "home" && <MouseParticles />}*/}
      <div
        className={`absolute w-2/4 h-full  top-0 bottom-20 flex flex-col justify-center gap-10 ${
          isAboveSmallScreen ? " right-0 items-end" : "  right-0 items-center"
        }`}
      >
        <div
          className={` h-2/4 w-full flex flex-col justify-center gap-10  absolute  right-[calc(120px)]`}
        >
          <div className={`${getTitleDimens()} text-white text-right`}>
            Team
          </div>
          <div className={`${getSubtitleDimens()} text-white  text-right`}>
            <p className="project-intro">
              Our strength lies in our team of dedicated professionals
            </p>
            <p className="project-methodology mt-10">
              We are a diverse group of experts committed to delivering
              excellence in every project
            </p>
          </div>{" "}
          <NavLink
            to={`/team`}
            onClick={() => {
              setSelectedPage(SelectedPage.Project);
            }}
          >
            <div
              className={`  ${
                buttonHover
                  ? ` p-1 text-right ${getButtonTextDimensHover()}`
                  : `p-1 text-right ${getButtonTextDimens()}`
              } transition-width duration-300 ease-in-out `}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
            >
              <button
                className=" px-10 py-1 font-semibold  bg-white text-blue-black "
                onClick={scrollToAbout}
              >
                read more
              </button>
            </div>
          </NavLink>
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Team;

import { useRef } from "react";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import ScrollToTopButton from "../../Components/ScrollToTopButton";
import BannerImage from "../../assets/backgroundPrivate.png";
import Test from "../../assets/test.png";
import LogoWhite from "../../assets/logo_white.png";
import Architectures from "../../assets/architectures.jpg";
import FishRobots from "../../assets/fishRobots.png";
import Footer from "../../Components/Footer";
import MURLogo from "../../assets/mur.png";
import IT from "../../assets/flags/it.png";
import FooterMain from "../../Components/FooterMain";
import {
  parti1,
  part2,
  part3,
  part4,
  part5,
  part6,
  part7,
  part8,
} from "./textString";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;

  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;

  previousDiv: String;
  setPreviousDiv: (value: String) => void;

  setSelectedPage(value: SelectedPage): void;
};

function Project({
  buttonHover,
  setButtonHover,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
  previousDiv,
  setPreviousDiv,
  setSelectedPage,
}: Props) {
  const aboutRef = useRef<HTMLDivElement>(null);
  const isAboveSmallcreens = useMediaQuery(Screens.Small);

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  const ref = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-8xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen) {
      return "text-3xl";
    } else if (isAboveSmallScreen) {
      return "text-2xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen) {
      return "text-xl";
    } else if (isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg text-center";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  return (
    <main
      style={{ padding: 0, margin: 0, boxSizing: "border-box" }}
      className="no-scrollbar flex-1 snap-y snap-mandatory    overflow-y-auto overflow-x-hidden"
    >
      <div
        className="bg-cover "
        id="prjoect"
        onMouseEnter={() => {
          setPreviousDiv(selectedDiv);
          setSelectedDiv("project");
        }}
        ref={ref}
        style={{
          backgroundImage: `url(${BannerImage})`,
        }}
      >
        <div
          className={`relative  w-full  bg-cover bg-center  flex flex-col items-center justify-start pt-10 px-20 text-justify`}
        >
          <div className={`w-full text-white ${getTitleDimens()}  `}>
            MAXFISH
          </div>
          <div
            className={`w-full text-white ${getSubtitleDimens()}  my-[calc(20px)]`}
          >
            Multi agents systems and Max-Plus algebra theoretical frameworks for
            a robot-fish shoal modelling and control
          </div>
          <div
            className={` px-10 text-white ${getTextDimens()}  my-[calc(20px)] flex gap-[calc(20px)] justify-start items-center`}
          >
            <div className="w-1/2   items-center justify-center flex">
              <img className="w-full " src={LogoWhite} alt="" />
            </div>
            <div className="w-1/2 h-full items-center justify-center flex text-justify">
              {parti1}
            </div>
          </div>
          <div
            className={`px-10 w-full text-white ${getTextDimens()}  my-[calc(20px)] flex gap-[calc(20px)] text-justify`}
          >
            {part2}
          </div>{" "}
          <div
            className={`px-10 text-white ${getTextDimens()}  my-[calc(20px)] flex gap-[calc(60px)] justify-center items-center`}
          >
            {" "}
            <div className="w-1/2 h-full items-center justify-center flex text-justify">
              {part3}
            </div>
            <div className="w-1/3   items-center justify-center flex">
              <img className="w-full " src={Architectures} alt="" />
            </div>
          </div>
          <div
            className={`px-10 w-full text-white ${getTextDimens()}  my-[calc(20px)] flex gap-[calc(20px)] text-justify`}
          >
            {part4}
          </div>{" "}
          <div
            className={` px-10 text-white ${getTextDimens()}  my-[calc(20px)] flex gap-[calc(60px)] justify-center items-center`}
          >
            <div className="w-1/3   items-center justify-center flex">
              <img className="w-full " src={FishRobots} alt="" />
            </div>
            <div className="w-1/2 h-full items-center justify-center flex text-justify">
              {part5}
            </div>
          </div>
          <div
            className={`px-10 w-full text-white ${getTextDimens()}  my-[calc(20px)] flex gap-[calc(20px)] text-justify`}
          >
            {part6}
          </div>{" "}
          <div
            className={`px-10  w-full text-white ${getTextDimens()}  my-[calc(20px)] flex gap-[calc(20px)] text-justify`}
          >
            {part7}
          </div>{" "}
          <div
            className={`px-10  w-full text-white ${getTextDimens()}  my-[calc(20px)] flex gap-[calc(20px)] text-justify`}
          >
            {part8}
          </div>{" "}
          <div className=" w-full h-40  flex  justify-end items-end gap-4">
            <div className=" w-40 h-40  flex flex-col justify-center items-center gap-4">
              {" "}
              <a href="https://www.mur.gov.it/it" target="_new">
                <div
                  className="w-12 h-12 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${MURLogo})`,
                  }}
                />
              </a>{" "}
              <div className={`text-xs text-white`}>
                20225RYMJE
              </div>
              <div
                className={`flex w-full  h-[calc(20px)] justify-center items-center gap-5`}
              >
                <img className="w-1/5" src={IT} />

                <div className="text-white w-4/5">Progetto PRIN</div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>{" "}
    </main>
  );
}

export default Project;

import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../../shared/types";
import BannerImage from "../../../../assets/background3.png";
import Footer from "../../../../Components/Footer";
import Unicas from "../../../../assets/unicas.png";
import Univpm from "../../../../assets/univpm.png";
import Unibo from "../../../../assets/unibo.png";
import MurLogo from "../../../../assets/mur.png";
import IT from "../../../../assets/flags/it.png";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;
  selectedDiv: String;
  scrollToAbout: () => void;
  setSelectedPage: (value: SelectedPage) => void;
};

const ContactsMobile = ({
  buttonHover,
  setButtonHover,
  selectedDiv,
  scrollToAbout,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  return (
    <div
      className={`relative  w-full snap-start bg-cover bg-center ${
        isAboveSmallScreen ? `${getPageDimens()}` : "h-screen bg-cover "
      }`}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      <div
        className={` h-full w-full flex flex-col justify-start gap-10 items-center px-10 `}
      >
        <div className={`${getTitleDimens()} text-white text-center`}>
          Contacts
        </div>
        <p className={`text-white ${getSubtitleDimens()}`}>
          Have questions, feedback, or just want to get in touch? We'd love to
          hear from you!
        </p>
        <div className="flex flex-row gap-20  justify-center items-center">
          <a
            href="https://www.unicas.it/"
            target="_new"
            className="flex flex-col gap-2 w-full justify-center items-center"
          >
            <img className="w-full" src={Unicas} />{" "}
            <p className="text-white">Unicas</p>
          </a>
          <a
            href="https://www.univpm.it/"
            target="_new"
            className="flex flex-col gap-2 w-full justify-center items-center"
          >
            {" "}
            <img className="w-full" src={Univpm} />{" "}
            <p className="text-white">Univpm</p>
          </a>{" "}
          <a
            href="https://www.unibo.it/it"
            target="_new"
            className="flex flex-col gap-2 w-full justify-center items-center"
          >
            {" "}
            <img className="w-full" src={Unibo} />{" "}
            <p className="text-white">Unibo</p>
          </a>
        </div>
        <div className="text-white text-xl text-center">
          <p>
            <a href="mailto:antonelli@unicas.it">antonelli@unicas.it</a>
          </p>
          <p>
            <a href="mailto:d.scaradozzi@univpm.it">d.scaradozzi@univpm.it</a>
          </p>
          <p>
            <a href="mailto:elena.zattoni@unibo.it">elena.zattoni@unibo.it</a>
          </p>
        </div>{" "}
        <div className=" w-40 h-40  flex flex-col justify-center items-center gap-4">
          <a href="https://www.mur.gov.it/it" target="_new">
            <div
              className="w-12 h-12 bg-cover bg-center"
              style={{
                backgroundImage: `url(${MurLogo})`,
              }}
            />
          </a>{" "}
          <div className={`   text-white text-xs`}>20225RYMJE</div>
          <img className="w-1/5" src={IT} />
          <div className="text-white">Progetto PRIN</div>
        </div>{" "}
      </div>
    </div>
  );
};

export default ContactsMobile;

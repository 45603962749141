import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import MURLogo from "../../../assets/mur.png";
import BannerImage from "../../../assets/background5.png";
import { NavLink } from "react-router-dom";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;
  selectedDiv: String;
  scrollToAbout: () => void;
  setSelectedPage: (value: SelectedPage) => void;
};

const Publication = ({
  buttonHover,
  setButtonHover,
  selectedDiv,
  scrollToAbout,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-4xl";
    } else if (isAboveLargeScreen) {
      return "text-4xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getTitle2Dimens(): String {
    if (isAboveHugeScreen) {
      return "text-6xl";
    } else if (isAboveLargeScreen) {
      return "text-6xl";
    } else if (isAboveMediumScreen) {
      return "text-4xl";
    } else if (isAboveSmallScreen) {
      return "text-3l";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  return (
    <div
      className={`relative  w-full snap-start bg-cover bg-center ${
        isAboveSmallScreen
          ? `${getPageDimens()}`
          : "h-[calc(100vh-120px)] bg-cover "
      }`}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      {" "}
      {/* {selectedDiv === "home" && <MouseParticles />}*/}
      <div
        className={`absolute  top-0 bottom-20 flex flex-col right-0 justify-center gap-10 ${
          isAboveSmallScreen
            ? " left-[calc(120px)]  items-left"
            : "left-0 items-center"
        }`}
      >
        <div className={` h-2/4 w-3/4 flex flex-col justify-center gap-10  `}>
          <div className={`${getTitleDimens()} text-white text-left`}>
            Publications
          </div>
          <div className={`${getSubtitleDimens()} text-white  text-left`}>
            <p className="project-intro">
              Explore our latest publications and research articles. Our
              dedicated team of experts is committed to advancing knowledge in
              various fields
            </p>
            <p className="mt-[calc(20px)]">
              Dive into our collection of insightful publications that cover a
              wide range of topics
            </p>
          </div>{" "}
          <NavLink
            to={`/publications`}
            onClick={() => {
              setSelectedPage(SelectedPage.Project);
            }}
          >
            <div
              className={`  ${
                buttonHover
                  ? ` p-1 text-left ${getButtonTextDimensHover()}`
                  : `p-1 text-left ${getButtonTextDimens()}`
              } transition-width duration-300 ease-in-out `}
              onMouseEnter={() => setButtonHover(true)}
              onMouseLeave={() => setButtonHover(false)}
            >
              <button
                className=" px-10 py-1 font-semibold  bg-white text-blue-black "
                onClick={scrollToAbout}
              >
                read more
              </button>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Publication;

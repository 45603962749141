import { useEffect, useState } from "react";
import Logo from "../../assets/logoOnlyWrite.png";
import LinkItem from "./Link";
import { Screens, SelectedPage } from "../../shared/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import Background from "../../assets/background.png";
import MenuPage from "../menu";
import HamburgerMenu from "../HamburgerMenu";
//import HamburgerMenu from "../../components/HamburgerMenu";

type Props = {
  selectedPage: SelectedPage;
  setSelectedPage: (value: SelectedPage) => void;
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;
};

const Navbar = ({
  selectedPage,
  setSelectedPage,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
}: Props) => {
  const flexBetween = "flex items-center justify-between";
  const isAboveSmallScreens = useMediaQuery(Screens.Small);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return "h-whiteBorderL";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "h-whiteBorderM";
    } else {
      return "h-whiteBorderS";
    }
  }

  function getSpacing(): String {
    if (isAboveHugeScreen) {
      return "  mx-whiteSpacingXL";
    } else if (isAboveLargeScreen) {
      return " mx-whiteSpacingL";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return " mx-whiteSpacingM";
    } else {
      return " mx-whiteSpacingXS";
    }
  }

  function getTextDimen(): String {
    if (isAboveHugeScreen) {
      return "  text-lg";
    } else if (isAboveLargeScreen) {
      return " text-lg";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "text-sm";
    } else {
      return " text-sm";
    }
  }

  function getBannerDimens(): String {
    if (isAboveHugeScreen) {
      return "  w-72";
    } else if (isAboveLargeScreen) {
      return " w-72";
    } else if (isAboveMediumScreen || isAboveSmallScreens) {
      return "w-56";
    } else {
      return " w-48";
    }
  }

  return (
    <nav id="navDiv">
      <div
        className={`${flexBetween}  top-0 z-30  w-full bg-cover bg-center ${
          selectedDiv != "home"
            ? selectedDiv == "publications"
              ? "bg-navBlue transition-colors duration-500"
              : "bg-blue-black transition-colors duration-500"
            : "transition-colors duration-500"
        }`}
      >
        <div className={`${flexBetween} ${getHeight()} w-full`}>
          <div
            className={`${flexBetween} w-full px-0  ${
              isAboveSmallScreens ? ` ${getSpacing()} ` : ""
            }`}
          >
            <div className={`${flexBetween} w-full`}>
              <div
                className={`${flexBetween} ${getBannerDimens()}  ${
                  isAboveSmallScreens ? "  " : "ml-4 "
                }`}
              >
                <img alt="logo" src={Logo} />
              </div>
            </div>
            {isAboveSmallScreens && (
              <div className={`${flexBetween} `}>
                <div
                  className={`flex flex-row items-center justify-end gap-6 ${getTextDimen()}${
                    isAboveSmallScreens ? " mr-[calc(20px)]  " : ""
                  }`}
                >
                  <LinkItem
                    page="HOME"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <LinkItem
                    page="PROJECT"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <LinkItem
                    page="TEAM"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <LinkItem
                    page="PUBLICATIONS"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <LinkItem
                    page="CONTACTS"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                  <LinkItem
                    page="PRIVATE"
                    selectedPage={selectedPage}
                    setSelectedPage={setSelectedPage}
                  />
                </div>
              </div>
            )}
          </div>

          {!isAboveSmallScreens && (
            <HamburgerMenu
              isMenuToggled={isMenuToggled}
              setIsMenuToggled={setIsMenuToggled}
              style={{ zIndex: 80 }}
            />
          )}
        </div>
        <MenuPage
          isMenuToggled={isMenuToggled}
          selectedPage={selectedPage}
          setIsMenuToggled={setIsMenuToggled}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { Screens } from "../shared/types";

interface TextFieldProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  type: string;
  id: string;
  page: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  value,
  onChange,
  type,
  id,
  page,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-lg";
    } else if (isAboveLargeScreen) {
      return "text-lg";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-sm";
    } else {
      return "text-sm";
    }
  }

  return (
    <div className="w-full">
      <input
        id={id}
        name={id}
        className={`mt-1 w-full  ${
          page
            ? "focus-ring-secondBlue bg-white text-blue-black border-secondBlue border-2"
            : "bg-midnight text-ash focus:ring-ash "
        } px-3 py-2    focus:outline-none focus:ring-2 ${getTextDimens()}`}
        type={type}
        value={value}
        onChange={handleInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={label}
      />
    </div>
  );
};

export default TextField;

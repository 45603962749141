import React, { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { Screens } from "../shared/types";

type Props = {
  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;
  style?: React.CSSProperties;
};

const HamburgerMenu = ({ isMenuToggled, setIsMenuToggled, style }: Props) => {
  const [isMouseOver, setMouseOver] = useState<boolean>(false);

  const isAboveSmallScreens = useMediaQuery(Screens.Small);

  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const genericHamburgerLine = `h-1 w-full ${getMargin()}  transition ease transform duration-300 rounded`;

  function getWidth(): String {
    if (isAboveHugeScreen) {
      return " w-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return " w-whiteBorderL";
    } else if (isAboveMediumScreen) {
      return "w-whiteBorderM";
    } else {
      return " w-whiteBorderS";
    }
  }

  function getHeight(): String {
    if (isAboveHugeScreen) {
      return " h-whiteBorderXL";
    } else if (isAboveLargeScreen) {
      return " h-whiteBorderL";
    } else if (isAboveMediumScreen) {
      return "h-whiteBorderM";
    } else {
      return " h-whiteBorderS";
    }
  }

  function getMargin(): String {
    if (isAboveHugeScreen) {
      return " my-1";
    } else if (isAboveLargeScreen) {
      return " my-1";
    } else if (isAboveMediumScreen) {
      return "my-1";
    } else {
      return "my-1";
    }
  }

  return (
    <div style={style} className="mx-0 ">
      <button
        className={`${isMenuToggled ? "bg-transparent"  : "bg-transparent"} ${getWidth()} ${getHeight()} px-4   `}
        onClick={() => setIsMenuToggled(!isMenuToggled)}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <div className="flex flex-col items-center justify-center">
          <div
            className={`${genericHamburgerLine} ${
              isMenuToggled ? "translate-y-3 rotate-45 bg-white" : "bg-white"
            } `}
          />
          <div
            className={`${genericHamburgerLine} ${
              isMenuToggled ? "opacity-0 " : "bg-white"
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isMenuToggled
                ? "-translate-y-3 -rotate-45 bg-white"
                : "bg-white"
            } `}
          />
        </div>
      </button>
    </div>
  );
};

export default HamburgerMenu;

import React from "react";
import Unicas from "../assets/unicas.png";
import Univpm from "../assets/univpm.png";
import Unibo from "../assets/unibo.png";
import EB from "../assets/eb_circle.png";
import ANCy from "../assets/anycib.png";
import LabMacs from "../assets/labmacs.png";

interface CustomDialogProps {
  isOpen: boolean;
  onClose: () => void;
  circleIndex: number;
  cx: number; // X-coordinate of the hovered circle
  cy: number; // Y-coordinate of the hovered circle
  svgWidth: string;
  svgHeight: string;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  isOpen,
  onClose,
  circleIndex,
  cx,
  cy,
  svgWidth,
  svgHeight,
}) => {
  function getLogo(): string {
    if (circleIndex === 0) {
      return Unicas;
    } else if (circleIndex === 1) {
      return Univpm;
    } else if (circleIndex === 2) {
      return Unibo;
    } else {
      return "";
    }
  }

  function getLogo2(): string {
    if (circleIndex === 0) {
      return EB;
    } else if (circleIndex === 1) {
      return ANCy;
    } else if (circleIndex === 2) {
      return Unibo;
    } else {
      return "";
    }
  }

  function getLogo3(): string {
    if (circleIndex === 0) {
      return EB;
    } else if (circleIndex === 1) {
      return LabMacs;
    } else if (circleIndex === 2) {
      return Unibo;
    } else {
      return "";
    }
  }

  function getNumberOfLogos(): number {
    if (circleIndex === 0) {
      return 2;
    } else if (circleIndex === 1) {
      return 3;
    } else if (circleIndex === 2) {
      return 1;
    } else {
      return 0;
    }
  }

  function getText(): JSX.Element {
    if (circleIndex === 0) {
      return (
        <div>
          <p className="text-white">Università degli Studi di Cassino e</p>
          <p className="text-white">del Lazio Meridionale</p>
        </div>
      );
    } else if (circleIndex === 1) {
      return (
        <div>
          <p className="text-white">Università Politecnica delle Marche</p>
        </div>
      );
    } else if (circleIndex === 2) {
      return (
        <div>
          <p className="text-white">Università di Bologna</p>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  function getText2(): JSX.Element {
    if (circleIndex === 0) {
      return (
        <div>
          <p className="text-white">EveryBotics</p>
        </div>
      );
    } else if (circleIndex ===1) {
      return (
        <div>
          <p className="text-white">ANcybernetics</p>
        </div>
      );
    } else if (circleIndex === 2) {
      return (
        <div>
          <p className="text-white">Università di Bologna</p>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  function getText3(): JSX.Element {
    if (circleIndex === 0) {
      return (
        <div>
          <p className="text-white">EveryBotics</p>
        </div>
      );
    } else if (circleIndex === 1) {
      return (
        <div>
          <p className="text-white">LabMACS</p>
        </div>
      );
    } else if (circleIndex === 2) {
      return (
        <div>
          <p className="text-white">Università di Bologna</p>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  return (
    <div
      className={`dialog ${
        isOpen ? "visible" : "hidden"
      } w-full h-[calc(120px)]  absolute top-10 right-0 left-0 bottom-0 flex flex-col items-center`}
    >
      <div className="flex bg-blue-black shadow-xl flex-row w-[calc(400px)] h-[calc(120px)] items-center justify-start gap-4">
        <img className="w-[calc(80px)] h-[calc(80px)]" src={getLogo()} />{" "}
        {getText()}
      </div>

      {getNumberOfLogos() > 1 && (
        <div className="flex bg-blue-black shadow-xl flex-row w-[calc(400px)] h-[calc(120px)] items-center justify-start gap-4 mt-5">
          <img className="w-[calc(80px)] h-[calc(80px)]" src={getLogo2()} />{" "}
          {getText2()}
        </div>
      )}

      {getNumberOfLogos() > 2 && (
        <div className="flex bg-blue-black shadow-xl flex-row w-[calc(400px)] h-[calc(120px)] items-center justify-start gap-4 mt-5">
          <img className="w-[calc(80px)] h-[calc(80px)]" src={getLogo3()} />{" "}
          {getText3()}
        </div>
      )}
    </div>
  );
};

export default CustomDialog;

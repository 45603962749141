import { useEffect, useRef, useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Screens, SelectedPage } from "../../../shared/types";
import BannerImage from "../../../assets/backgroundPrivate.png";
import Footer from "../../../Components/Footer";
import Unicas from "../../../assets/unicas.png";
import Unibo from "../../../assets/unibo.png";
import Univpm from "../../../assets/univpm.png";
import MurLogo from "../../../assets/mur.png";
import IT from "../../../assets/flags/it.png";
import Test from "../../../assets/test.png";
import AnyCib from "../../../assets/anycib.png";
import EBCircle from "../../../assets/eb_circle.png";
import LabMacs from "../../../assets/labmacs.png";

import Antonelli from "../../../assets/antonelli.png";
import Scaradozzi from "../../../assets/scaradozzi.jpeg";
import Zattoni from "../../../assets/zattoni.png";
import Arrichiello from "../../../assets/arrichiello.jpg";
import Marino from "../../../assets/marino.jpg";
import DiLillo from "../../../assets/dilillo.png";
import Bartolucci from "../../../assets/bartolucci.jpeg";
import Screpanti from "../../../assets/screpanti.jpeg";

import DiVito from "../../../assets/divito.jpeg";

import Gillini from "../../../assets/gillini.png";

import DiNardo from "../../../assets/dinardo.jpeg";
import Ciuccoli from "../../../assets/ciuccoli.jpg";
import Costa from "../../../assets/costa.jpeg";
import Fiara from "../../../assets/fiara.jpeg";
import Castagna from "../../../assets/castagna.png";

type Props = {
  buttonHover: boolean;
  setButtonHover: (value: boolean) => void;

  isMenuToggled: boolean;
  setIsMenuToggled: (value: boolean) => void;

  selectedDiv: String;
  setSelectedDiv: (value: String) => void;

  previousDiv: String;
  setPreviousDiv: (value: String) => void;

  setSelectedPage(value: SelectedPage): void;
};

const PageTeam = ({
  buttonHover,
  setButtonHover,
  isMenuToggled,
  setIsMenuToggled,
  selectedDiv,
  setSelectedDiv,
  previousDiv,
  setPreviousDiv,
  setSelectedPage,
}: Props) => {
  const isAboveHugeScreen = useMediaQuery(Screens.Huge);
  const isAboveLargeScreen = useMediaQuery(Screens.Large);
  const isAboveMediumScreen = useMediaQuery(Screens.Medium);
  const isAboveSmallScreen = useMediaQuery(Screens.Small);

  function getTitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-9xl";
    } else if (isAboveLargeScreen) {
      return "text-9xl";
    } else if (isAboveMediumScreen) {
      return "text-6xl";
    } else if (isAboveSmallScreen) {
      return "text-4xl";
    } else {
      return "text-4xl text-center";
    }
  }

  function getSubtitleDimens(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen) {
      return "text-2xl";
    } else if (isAboveSmallScreen) {
      return "text-xl";
    } else {
      return "text-xl text-center";
    }
  }

  function getButtonTextDimens(): String {
    if (isAboveHugeScreen) {
      return "text-2xl";
    } else if (isAboveLargeScreen) {
      return "text-2xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-base";
    } else {
      return "text-base";
    }
  }

  function getButtonTextDimensHover(): String {
    if (isAboveHugeScreen) {
      return "text-3xl";
    } else if (isAboveLargeScreen) {
      return "text-3xl";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return "text-lg";
    } else {
      return "text-lg";
    }
  }

  function getPageDimens(): String {
    if (isAboveHugeScreen) {
      return "h-[calc(100vh-80px)]]";
    } else if (isAboveLargeScreen) {
      return " h-[calc(100vh-80px)]";
    } else if (isAboveMediumScreen || isAboveSmallScreen) {
      return " h-[calc(100vh-70px)]";
    } else {
      return "h-[calc(100vh-70px )]]";
    }
  }

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className="w-full  mb-[calc(60px)] bg-repeat bg-cover"
      id="marche"
      onMouseEnter={() => {
        setPreviousDiv(selectedDiv);
        setSelectedDiv("marche");
      }}
      ref={ref}
      style={{
        backgroundImage: `url(${BannerImage})`,
      }}
    >
      <div
        className={`relative  w-full h-full bg-cover bg-center flex flex-col items-center justify-start pt-[calc(40px)]   mb-[calc(60px)]  px-20 gap-10`}
      >
        <div className={`text-white ${getTitleDimens()}`}>Team</div>
        <div className="w-full h-full flex flex-col items-center justify-center gap-5">
          <div className="w-full h-1/3 flex  items-center justify-center  ">
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Antonelli})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-5">
                <div className="w-1/5 h-full flex flex-col items-center justify-center ">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${Unicas})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.unicas.it/" target="_new">
                      UNICAS
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Gianluca Antonelli
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:antonelli@unicas.it">antonelli@unicas.it</a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://sites.google.com/unicas.it/gianluca-antonelli"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Scaradozzi})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${Univpm})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.univpm.it/Entra/" target="_new">
                      UNIVPM
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  David Scaradozzi
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:d.scaradozzi@univpm.it">
                  d.scaradozzi@univpm.it
                </a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://www.linkedin.com/in/david-scaradozzi-761a9823/?originalSubdomain=it"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Zattoni})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${Unibo})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.unibo.it/it" target="_new">
                      UNIBO
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Elena Zattoni
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:elena.zattoni@unibo.it">
                  elena.zattoni@unibo.it
                </a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://www.unibo.it/sitoweb/elena.zattoni"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
          <div className="w-full h-1/3 flex  items-center justify-center  ">
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Arrichiello})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${Unicas})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.unicas.it/" target="_new">
                      UNICAS
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Filippo Arrichiello
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:f.arrichiello@unicas.it">
                  f.arrichiello@unicas.it
                </a>
              </div>
              <div className={`text-white `}>
                <a href="https://webuser.unicas.it/arrichiello/" target="_new">
                  Read more
                </a>
              </div>
            </div>
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Marino})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${Unicas})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.unicas.it/" target="_new">
                      UNICAS
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Alessandro Marino
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:al.marino@unicas.it">al.marino@unicas.it</a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://webuser3.unicas.it/alessandro-marino/"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${DiLillo})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${Unicas})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.unicas.it/" target="_new">
                      UNICAS
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Paolo Di Lillo
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:pa.dilillo@unicas.it">pa.dilillo@unicas.it</a>
              </div>
              <div className={`text-white `}>
                <a href="https://paolodilillo.wordpress.com/" target="_new">
                  Read more
                </a>
              </div>
            </div>
          </div>
          <div className="w-full h-1/3 flex  items-center justify-center  ">
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Castagna})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${LabMacs})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.labmacs.university/" target="_new">
                      LabMACS
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Benedetta Castagna
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:b.castagna@univpm.it">b.castagna@univpm.it</a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://www.labmacs.university/membro-staff/benedetta-castagna-eng/"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${DiNardo})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${LabMacs})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.labmacs.university//" target="_new">
                    LabMACS
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Francesco Di Nardo
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:f.dinardo@univpm.it">f.dinardo@univpm.it</a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://www.labmacs.university/membro-staff/francesco-di-nardo-eng/"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Ciuccoli})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${Univpm})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.univpm.it/Entra/" target="_new">
                      UNIVPM
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Nicolò Ciuccoli
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:n.ciuccoli@univpm.it">n.ciuccoli@univpm.it</a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://simau.univpm.it/nicolo-ciuccoli/"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
          s
          <div className="w-3/4 h-1/3 flex  items-center justify-center  ">
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Bartolucci})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${Unibo})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.unibo.it/it" target="_new">
                      UNIBO
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Veronica Bartolucci
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:veronica.bartolucci4@unibo.it">
                veronica.bartolucci4@unibo.it
                </a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://www.unibo.it/sitoweb/veronica.bartolucci4
                  "
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>

            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Costa})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${AnyCib})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.ancybernetics.it/" target="_new">
                      ANcybernetics
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Daniele Costa
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:d.costa@univpm.it">d.costa@univpm.it</a>
              </div>
              <div className={`text-white `}>
                <a href="https://diism.univpm.it/daniele-costa/" target="_new">
                  Read more
                </a>
              </div>
            </div>

            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Fiara})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${AnyCib})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.ancybernetics.it/" target="_new">
                      ANcybernetics
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Giacomo Fiara
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:g.fiara@ancybernetics.it">
                  g.fiara@ancybernetics.it
                </a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://www.linkedin.com/in/giacomo-fiara-7733b0222/"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
          <div className="w-full h-1/3 flex  items-center justify-center  ">
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${DiVito})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${EBCircle})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.everybotics.it/" target="_new">
                      EveryBotics
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Daniele Di Vito
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:daniele.divito@everybotics.it">
                  daniele.divito@everybotics.it
                </a>
              </div>
              <div className={`text-white `}>
                <a
                  href="https://www.danieledivitoengineering.it/"
                  target="_new"
                >
                  Read more
                </a>
              </div>
            </div>
            <div className="w-1/4 h-full flex flex-col items-center justify-start">
              <div
                className="w-40 h-40 bg-cover bg-center rounded-full mx-auto mb-3"
                style={{ backgroundImage: `url(${Gillini})` }}
              />

              <div className="w-full h-1/6 flex  items-center justify-center gap-5 mt-2">
                <div className="w-1/5 h-full flex flex-col items-center justify-center">
                  <div
                    className="w-full h-10 bg-contain bg-no-repeat bg-center "
                    style={{ backgroundImage: `url(${EBCircle})` }}
                  />{" "}
                  <div className="text-white text-center text-xs">
                    <a href="https://www.everybotics.it/" target="_new">
                      EveryBotics
                    </a>
                  </div>
                </div>
                <div className={`text-white ${getSubtitleDimens()} `}>
                  {" "}
                  Giuseppe Gillini
                </div>
              </div>

              <div className="text-white mt-5">
                <a href="mailto:giuseppe.gillini@everybotics.it">
                  giuseppe.gillini@everybotics.it
                </a>
              </div>
              <div className={`text-white `}>
                <a href="https://www.giuseppegillini.it/" target="_new">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className=" absolute bottom-10 right-20 flex w-40 h-40 flex-col justify-center items-center gap-4">
          {" "}
          <a href="https://www.mur.gov.it/it" target="_new">
            <div
              className="w-12 h-12 bg-cover bg-center"
              style={{
                backgroundImage: `url(${MurLogo})`,
              }}
            />
          </a>
          <div className={`text-xs text-white`}>20225RYMJE</div>
          <div
            className={`flex w-full  h-[calc(20px)] justify-center items-center gap-5`}
          >
            <img className="w-1/5" src={IT} />

            <div className="text-white w-4/5">Progetto PRIN</div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default PageTeam;
